import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import analytics from "../../assets/images/bg5.jpg";  
import theme from "../../theme/theme";
import React, { useState } from "react";
// import TextInput, { TextInputFont } from "../../components/input";
import BadgeIcon from "@mui/icons-material/Badge";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useNavigate } from "react-router-dom";
import { FamilyRestroom } from "@mui/icons-material";
import { postCustomerCode } from "../action";
import { fAlertToast } from "../../Utility/Utility";
import TypographyLabel from "../../components/typographyLabel";
import HeadingText from "../../components/headingText";
import ButtonComp from "../../components/button";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TextInputFont } from "../Component/input";
const useStyles = makeStyles(() => ({
  container: {
    backgroundColor:"#83D475" ,
    // backgroundColor:"rgb(108 117 125 / 71%)" ,
    // background: "linear-gradient(to bottom, #3349ffbf 0%, #ff66ff9c 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    
  },
  style: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //margin: "auto",
    //padding: 2,
    margin: "12px",
    borderRadius: "20px",
    width: 600,
    height: 200,
    fontFamily: theme.typography.h2,
  },
}));
const CustomerCode = ({ xsetFormData }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const handleRoute = (route) => {
    if (route) {
      navigate(route);
    }
  };

  const [userId, setUserID] = useState({
    customerCode: "",
  });
  const [error, setError] = useState({
    customerCode: false,
  });
  const [message, setMessage] = useState({
    customerCode: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setUserID((preState) => ({
      ...preState,
      [name]: value,
    }));
  };

  const fNext = async () => {
    let obj = {
      v_UId: userId.customerCode,
    };
    const data = await postCustomerCode(obj);
    if (data.status == 200 && data.data.ResultSet.length > 0) {
      let currentdata = data.data.ResultSet[0];

      xsetFormData((obj) => ({
        ...obj,
        customerCode: currentdata.CustSrNo||"",
        firstName: currentdata.FName||"",
        lastName: currentdata.LName||"",
        gender: currentdata.Gender||"",
        nationality: currentdata.Country||"",
        mobile: currentdata.Mobile||"",
        email: currentdata.Email||"",
        type: currentdata.SPLGRP||"",
        UId: currentdata.UId||"",
        refNo: currentdata.Cashier||"",
      }));
    } else {
      fAlertToast("WARNING", "Customer not found");
    }
  };

  return (
    <Box className={classes.container}  style={{
      // backgroundColor: "rgb(108 117 125 / 71%)",
      // backgroundColor: "#83D475",
      backgroundImage:`url(${analytics})`,
      backgroundRepeat: "no-repeat", 
      height: "100vh",
      backgroundSize: "cover", 
    }}>
      <Card className={classes.style} >
        <CardContent className="landingPage">
          <Grid container alignItems={"center"}>
            <Grid item xs={12}>
              <TextInputFont
             
                name={"customerCode"}
                type={"text"}
                label={"Customer Code"}
                variant="standard"
                value={userId?.customerCode}
                onChange={handleChange}
                error={Boolean(error?.customerCode)}
                helperText={message?.customerCode}
                // InputProps={
                //   endAdornment (
                //     <InputAdornment position="end">{<BadgeIcon />}
                //     </InputAdornment>
                //   )
                // }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
          // className={"btnSimplified"}
                              sx={{ 
                                width: "200px",
                                textTransform: "capitalize",
                                borderRadius: "25px",
                                border: "2px solid #388e3c",
                                backgroundColor: "#f7faff",
                                // color: "#388e3c",
                                color: "#ffff",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between", 
                                backgroundImage:`url(${analytics})`,
                                backgroundRepeat: "no-repeat",  
                                backgroundSize: "fill", 
                                backgroundPosition: "bottom",
                              }} 
                              size={"small"}
                              type={"submit"}
                              disabled={false}
                              onClick={() => {
                                setMessage({ customerCode: "" });
                                setError({ customerCode: false });
                                if (userId.customerCode == "") {
                                  setMessage({
                                    customerCode: "Customer field should not be empty",
                                  });
                                  setError({ customerCode: true });
                                } else {
                                  fNext();
                                }
                  
                                // handleRoute("/customer-details")
                              }}
                            >
                               <span style={{ display: "flex", alignItems: "center" }}>
                               <ArrowForwardIcon />
                  </span>
                  <span style={{ textAlign: "center", flex: 1 }}>Next</span>
                              
                            </Button>
          {/* <ButtonComp
           
            style={{
              width: "160px",
              textTransform: "capitalize",
            }}
            clickEvent={() => {
              setMessage({ customerCode: "" });
              setError({ customerCode: false });
              if (userId.customerCode == "") {
                setMessage({
                  customerCode: "Customer field should not be empty",
                });
                setError({ customerCode: true });
              } else {
                fNext();
              }

              // handleRoute("/customer-details")
            }}
            name={"Next"}
            color={"primary"}
            type="submit"
            size="small"
            variant="contained"
            
          /> */}
        </CardActions>
      </Card>
    </Box>
  );
};

export default CustomerCode;
