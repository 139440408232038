import React, { useCallback, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  Box,
  Autocomplete,
  InputAdornment,
  Grid,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateCustInfo } from "./slice";
import VerifyOTP from "./VerifyOTP/VerifyOTP";
import customers from "../../Utility/customers.json";
import countries from "../../Utility/country.json";
import { fAlertToast } from "../../Utility/Utility";
import { useSearchParams } from "react-router-dom";
import Profile from "./Profile";

const steps = ["Login", "OTP Verification", "Profile"];

const HeaderToolbar = ({ header }) => {
  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Toolbar sx={{ minHeight: "45px !important" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          {header}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const DeleteAccountFlow = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("ID");
  const name = searchParams.get("NAME");

  const customer = customers.find((customer) => customer.id === id);
  // console.log("customer", customer);

  const [activeStep, setActiveStep] = useState(0);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState({});
  const [errors, setErrors] = useState({});
  const defaultCountry = countries.find((country) => country.code === "AE");
  const { customerInfo } = useSelector((state) => state?.DeleteAccountSlice);

  // apis

  const registerMobileApi = (data) => {
    return {
      method: "POST",
      url: `${customer?.baseUrl}RegisterMobile`,
      data,
    };
  };

  const verifyCustomerOtpApi = (data) => {
    return {
      method: "POST",
      url: `${customer?.baseUrl}VerifyCustomer`,
      data,
    };
  };

  const VerifyOtp = useCallback(async () => {
    try {
      let fullOtp = Object.values(otp).join("");
      if (fullOtp) {
        const verifyCustomer = verifyCustomerOtpApi({
          CountryCd: "971",
          MobileNo: mobileNumber,
          Token: fullOtp,
        });
        const data = await fetchData(verifyCustomer);
        if (data?.Type === "Success") {
          const newObject = {
            custId: data?.ResultSet[0]?.CustID,
            loggedAt: new Date(),
            Fname: data?.ResultSet[0]?.Fname,
            Lname: data?.ResultSet[0]?.Lname,
            DOB: data?.ResultSet[0]?.DOB,
            gender: data?.ResultSet[0]?.Gender,
            LoyaltyID: data?.ResultSet[0]?.LoyaltyID,
            CountryCd: data?.ResultSet[0]?.CountryCd,
            CountryName: data?.ResultSet[0]?.CountryName,
            State: data?.ResultSet[0]?.State,
            Mobile: data?.ResultSet[0]?.Mobile,
            Email: data?.ResultSet[0]?.Email,
            GMLat: data?.ResultSet[0]?.GMLat,
            GMLong: data?.ResultSet[0]?.GMLong,
            Addr1: data?.ResultSet[0]?.Addr1,
            Addr2: data?.ResultSet[0]?.Addr2,
            Addr3: data?.ResultSet[0]?.Addr3,
            City: data?.ResultSet[0]?.City,
            DeviceID: data?.ResultSet[0]?.DeviceID,
            DeviceOS: data?.ResultSet[0]?.DeviceOS,
            DeviceOSVer: data?.ResultSet[0]?.DeviceOSVer,
            AppID: data?.ResultSet[0]?.AppID,
          };
          dispatch(updateCustInfo(newObject));
          if (data?.ResultSet[0]?.Fname) {
            setActiveStep((prevStep) => prevStep + 1);
            setOtp({});
          }
        }
      }
    } catch (error) {
      console.log(error);
      fAlertToast("FAILED", error?.response?.data?.Status || error?.message);
    }
  }, [otp, mobileNumber, dispatch]);

  const validateStep = () => {
    let stepErrors = {};
    if (activeStep === 0) {
      if (!mobileNumber || !/^[0-9]{9}$/.test(mobileNumber)) {
        stepErrors.mobileNumber = "Valid mobile number is required.";
      }
    } else if (activeStep === 1) {
      if (
        !Object.values(otp)
          .join("")
          .match(/^[0-9]{4}$/)
      ) {
        stepErrors.otp = "Valid OTP is required.";
      }
    }
    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const fetchData = async (apiConfig) => {
    try {
      const response = await axios(apiConfig);
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const handleNext = async () => {
    if (!validateStep()) return;

    if (activeStep === 0) {
      try {
        const registerApi = registerMobileApi({
          CountryCd: "971",
          MobileNo: mobileNumber,
        });
        const response = await fetchData(registerApi);
        if (response?.Type === "Success") {
          setActiveStep((prevStep) => prevStep + 1);
        }
      } catch (error) {
        console.error("Error during mobile registration:", error);
        fAlertToast("FAILED", error?.response?.data?.Status || error?.message);
      }
    } else if (activeStep === 1) {
      VerifyOtp();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  defaultValue={defaultCountry}
                  disableClearable
                  readOnly
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+971</InputAdornment>
                    ),
                  }}
                  error={!!errors.mobileNumber}
                  helperText={errors.mobileNumber}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <VerifyOTP
            otp={otp}
            setOtp={setOtp}
            handleSubmit={VerifyOtp}
            errors={errors}
          />
        );
      case 2:
        return (
          <Profile
            customerInfo={customerInfo}
            customer={customer}
            setActiveStep={setActiveStep}
            fetchData={fetchData}
          />
        );
      default:
        return <p>Unknown step</p>;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <HeaderToolbar header={name} />
      <Box sx={{ height: "90vh", overflow: "auto", padding: "0.5rem 1rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <IconButton
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ border: "1px solid", borderRadius: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>

          {activeStep !== 2 ? (
            <IconButton
              onClick={handleNext}
              sx={{
                border: "1px solid",
                borderRadius: 2,
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          ) : null}
        </Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ marginTop: 2 }}>{renderStepContent(activeStep)}</Box>
      </Box>
    </Box>
  );
};

export default DeleteAccountFlow;
