import { createSlice } from "@reduxjs/toolkit";

const DeleteAccountSlice = createSlice({
  name: "DeleteAccount",
  initialState: {
    customerInfo: {},
  },
  reducers: {
    updateCustInfo(state, action) {
      state.customerInfo = action.payload;
    },
  },
});

export const { updateCustInfo } = DeleteAccountSlice.actions;
export default DeleteAccountSlice.reducer;
