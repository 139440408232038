import React, { useState, useRef, useEffect } from "react";
import "./VerifyOTP.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Stack, Typography } from "@mui/material"; // IconButton, Typography
import theme from "../../../theme/theme";

const VerifyOTP = ({ otp, setOtp, handleSubmit, errors }) => {
  const inputRefs = useRef([]);

  const firstInputRef = useRef(null);
  const [timer, setTimer] = useState(600); // 10 minutes in seconds

  const handleChange = (e, index) => {
    const { value } = e.target;
    setOtp((prev) => ({ ...prev, [`otp${index + 1}`]: value }));
    if (value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus(); // Move focus to next input field after entering a digit
    }
  };

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
    const countdown = setInterval(() => {
      setTimer((prev) => {
        return prev > 0 ? prev - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleResendOTP = (e) => {
    setTimer(600);
    handleSubmit();
  };

  return (
    <>
      <Box
        style={{
          my: 8,
          mx: 4,
          marginBottom: "0.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "inherit",
          justifyContent: "space-evenly",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            width: "86%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", pb: 0 }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontWeight: 700, mb: 0.5 }}
            >
              OTP Verification
            </Typography>
            <Typography
              gutterBottom
              component="p"
              variant="p"
              sx={{ fontSize: "small", color: "#888097" }}
            >
              Please enter the OTP (One-Time Password) sent to your registered
              email/phone number to complete your verification.
            </Typography>
            <form autoComplete="off">
              <div className="verify-otp-container">
                {[0, 1, 2, 3].map((index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength={1}
                    value={otp[`otp${index + 1}`]}
                    autoComplete="off"
                    onChange={(e) => handleChange(e, index)}
                    style={{ borderColor: errors?.otp ? "red" : "" }}
                    ref={(el) => {
                      inputRefs.current[index] = el;
                      if (index === 0) {
                        firstInputRef.current = el;
                      }
                    }}
                  />
                ))}
              </div>
              {errors?.otp && (
                <p
                  style={{
                    color: "red",
                    fontSize: "small",
                    textAlign: "center",
                  }}
                >
                  {errors?.otp}
                </p>
              )}
            </form>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ margin: "8px 0 24px 0" }}
            >
              {timer > 0 ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  sx={{ fontSize: "x-small" }}
                >
                  <span style={{ color: "#888097" }}>Remaining time : </span>
                  <span style={{ color: theme.palette.primary.main }}>
                    {Math.floor(timer / 60)}:
                    {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                  </span>
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: "x-small", color: "#888097" }}
                >
                  <span style={{ color: "#888097" }}>
                    {" "}
                    Did not got the code?{" "}
                  </span>
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: "underline",
                    }}
                    onClick={handleResendOTP}
                  >
                    Resend
                  </span>
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default VerifyOTP;
