import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGoogleReviewUrl } from "../action";
import { fAlertToast } from "../../Utility/Utility";
import GoogleIcon from "@mui/icons-material/Google";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
// import logo from "../../assets/images/gravity_small_logo.png"; 
// import analytics from "../../assets/images/analytics.jpg";  
import analytics from "../../assets/images/bg2.jpg";  
import logo from "../../assets/images/logov1.png";
const Landing = () => {
  const navigate = useNavigate();
  const [GoogleReview, setGoogleReview] = useState([]);
  const params = useParams();
  let Location = params.Loc || "";

  const handleRoute = (route) => {
    if (route) {
      navigate(route);
    }
  };

  const onGoogleReview = async () => {
    let data = GoogleReview.find((review) => review.Uid === Location);
    if (!!data) {
      window.open(data.GoogleReviewURL, "_blank");
    } else {
      fAlertToast("FAILED", "Url setup is missing, Contact gravity");
    }
  };

  const onLoad = async () => {
    let data = await getGoogleReviewUrl();
    if (data.status === 200) {
      setGoogleReview(data.data.ResultSet);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Box
      style={{
        // backgroundColor: "rgb(108 117 125 / 71%)",
        // backgroundColor: "#83D475",
        backgroundImage:`url(${analytics})`,
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundSize: "cover", 
      }}
      className="landingPage"
    >
      <Container maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} style={{ textAlign: "center", marginBottom: "20px" }}>
            <img
              src={logo}// Replace with the path to your logo
              alt="Logo"
              
              style={{
                width: "80px",
                height: "80px",
                marginBottom: "90px",
                borderRadius: "50%",
                border: "0.5px solid #388e3c",
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                objectFit: "cover", // Ensures proper scaling without distortion
              }}
            />
             
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                backgroundColor: "unset",
                // backgroundColor: "#ffffff",
                // borderRadius: "20px",
                padding: "25px",
                boxShadow: "unset",
                textAlign: "center",
              }}
            >
              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Button
                  style={{
                    width: "200px",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                    border: "2px solid #388e3c",
                    backgroundColor: "#f7faff",
                    color: "#388e3c",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={onGoogleReview}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <GoogleIcon />
                  </span>
                  <span style={{ textAlign: "center", flex: 1 }}>Google Review</span>
                </Button>
                <Button
                  style={{
                    width: "200px",
                    textTransform: "capitalize",
                    borderRadius: "25px",
                    border: "2px solid #388e3c",
                    backgroundColor: "#f7faff",
                    color: "#388e3c",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={() => handleRoute("/customer-page")}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <LoyaltyIcon />
                  </span>
                  <span style={{ textAlign: "center", flex: 1 }}>Loyalty</span>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Landing;
