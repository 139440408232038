import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  webinstaitems: [],
  // selectedCard: {},
  selectedCardIndex: null,
};

const instaIntegrationSlice = createSlice({
  name: "instaIntegrationSlice",
  initialState: initialState,

  reducers: {
    setWebInstaItem: (state, action) => {
      state.webinstaitems = action.payload;
    },
    // setSelectedCard: (state, action) => {
    //   state.selectedCard = action.payload;
    // },
    setSelectedCardIndex: (state, action) => {
      state.selectedCardIndex = action.payload;
    },
  },
});

export const { setWebInstaItem, setSelectedCardIndex } =
  instaIntegrationSlice.actions;
export default instaIntegrationSlice.reducer;
