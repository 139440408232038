import {
  Box,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { updateCustInfo } from "./slice";
import { fAlertToast } from "../../Utility/Utility";
import { useDispatch } from "react-redux";

export default function Profile({
  customerInfo,
  customer,
  setActiveStep,
  fetchData,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); // State to manage dialog open/close

  const handleDeleteAccountApi = async (obj) => {
    return {
      method: "POST",
      url: `${customer?.baseUrl}DeleteCustomerAccount`,
      data: obj,
    };
  };

  const handleDelete = async () => {
    if (customerInfo?.custId) {
      const deleteApi = await handleDeleteAccountApi({
        CustId: customerInfo?.custId,
      });
      const response = await fetchData(deleteApi);
      if (response?.status === 200) {
        setActiveStep(0);
        dispatch(updateCustInfo({}));
        fAlertToast("SUCCESS", "Account deleted successfully");
        setOpen(false); // Close the dialog
      }
    } else {
      fAlertToast("FAILED", "Customer id not found");
    }
  };

  const handleDialogOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setOpen(false); // Close the dialog
  };

  return (
    <Box>
      <h3 style={{ fontSize: "1.5rem", marginBottom: "10px" }}>Profile</h3>
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        First Name :{" "}
        <span style={{ fontWeight: "normal" }}>{customerInfo?.Fname}</span>
      </p>
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        Last Name :{" "}
        <span style={{ fontWeight: "normal" }}>{customerInfo?.Lname}</span>
      </p>
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        Email :{" "}
        <span style={{ fontWeight: "normal" }}>{customerInfo?.Email}</span>
      </p>
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        Registered Mobile :{" "}
        <span style={{ fontWeight: "normal" }}>{customerInfo?.Mobile}</span>
      </p>
      <Stack direction={"row"} justifyContent={"center"} sx={{ mt: 5 }}>
        <Button variant="contained" color="error" onClick={handleDialogOpen}>
          Delete Account
        </Button>
      </Stack>

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
