import React from "react";
import { FormControl, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import theme from "../theme/theme";
//import { color } from "html2canvas/dist/types/css/types/color";

const TextInput = ({
  name,
  label,
  value,
  onChange,
  error,
  variant,
  helperText,
  type,
  width,
  startAdornment,
  passwordIcon,
  passwordVisibility,
  mouseDown,
  click,
  disabled,
  inputRef,
}) => {
  return (
    <FormControl className={"TextInputv1"} sx={{width:"100%"}} >
      <TextField fullWidth
        type={type}
        value={value}
        name={name}
        disabled={disabled}
        onChange={onChange}
        variant={variant}
        error={error}
        helperText={error && helperText}
        label={label}
        inputRef={inputRef ? inputRef : null}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: passwordIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={click}
                onMouseDown={mouseDown}
                edge="end"
              >
                {passwordVisibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#4caf50", // Default border color
            },
            "&:hover fieldset": {
              borderColor: "#81c784", // Hover border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#388e3c", // Focused border color
            },
          },
          "& .MuiInputLabel-root": {
            color: "#4caf50", // Default label color
            "&.Mui-focused": {
              color: "#388e3c", // Focused label color
            },
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "#4caf50", // Default underline color
          },
          "& .MuiInput-underline:hover:before": {
            borderBottomColor: "#81c784", // Hover underline color
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#388e3c", // Focused underline color
          },
        }}
      />
    </FormControl>
  );
};

export const TextInputFont = ({
  name,
  label,
  value,
  onChange,
  error,
  variant,
  helperText,
  type,
  width,
  endAdornment,
  startAdornment,
  passwordIcon,
  passwordVisibility,
  mouseDown,
  click,
  inputRef,
  disabled=false
}) => {
  
  return (
    <FormControl    className="TextInputFontv1"  >
      <TextField fullWidth
      disabled={disabled}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        variant={variant}
        error={error}
        helperText={error && helperText}
        label={label}
        inputRef={inputRef ? inputRef : null}
        className=""
        InputProps={{
          
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#4caf50", // Default border color
            },
            "&:hover fieldset": {
              borderColor: "#81c784", // Hover border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#388e3c", // Focused border color
            },
          },
          "& .MuiInputLabel-root": {
            color: "#4caf50", // Default label color
            "&.Mui-focused": {
              color: "#388e3c", // Focused label color
            },
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "#4caf50", // Default underline color
          },
          "& .MuiInput-underline:hover:before": {
            borderBottomColor: "#81c784", // Hover underline color
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#388e3c", // Focused underline color
          },
        }}
      />
    </FormControl>
  );
};

export default TextInput; 
